import { setCountryApi } from "../../helpers/multicountry_management";
import api, { strapiCall } from "../../utils/api";
import * as types from "../types/company_type";

const companyAttributes = `
  id
  name
  registerName
  typeId
  sizeId
  industry
  industryId
  logo
  shortDescription
  category
  coverImage
  coverImageThumb
  jobCount
  bookmark
  slug
  logo
  hide
  affiliatedCompany {
    company {
      id
    }
  }  
  companyAffiliation {
    affiliatedCompany {
      id
    }
  }
  
`;

const companyListsAttribute = `
  id
  name
  registerName
  typeId
  sizeId
  industry
  industryId
  logo
  shortDescription
  coverImage
  coverImageThumb
  jobCount
  bookmark
  slug
  logo
  hide
`;

const profileAttributes = `
  profile {
    imageSize
    imagePosition 
    address
    latitude
    longitude
    descriptions {
      title
      body
    }
    images {
      cover
      image
    }
    socialMediaLinks{
      sortOrder
      socialMediaLink
    }
    videos {
      title
      category
      vimeoId
      quote
      personName
      personPosition
      height
      width
    }
    extras {
      title
      vimeoId
    }
  }
`;

const jobAttributes = `
  id
  title
  image
  jobType
  tracks {
    id
    title
  }
  stateRegion
  location
  shortDescription
  requirements
  bookmark
  createdAt
  haveApplied
  haveRejected
  secondaryCompanyId
  expired
  slug
  maxYearsExperience
  minYearsExperience
  boosted
  company {
    id
    name
    jobCount    
    logo
    profile{
      id
    }
  }
`;

const clearCompanyList = () => async (dispatch) => {
  return new Promise((resolve) => {
    return resolve(
      dispatch({
        type: types.CLEAR_COMPANY_LIST,
      })
    );
  });
};

const clearCompanySuggestion = () => async (dispatch) => {
  return new Promise((resolve) => {
    return resolve(
      dispatch({
        type: types.CLEAR_COMPANY_SUGGESTION,
      })
    );
  });
};

const clearBookmarkCompanyList = () => async (dispatch) => {
  return new Promise((resolve) => {
    return resolve(
      dispatch({
        type: types.CLEAR_COMPANY_BOOKMARK_LIST,
      })
    );
  });
};

const updateCompanyFilter = (companyFilter) => async (dispatch) => {
  return new Promise((resolve) => {
    return resolve(
      dispatch({
        type: types.UPDATE_COMPANY_FILTER,
        companyFilter: companyFilter,
      })
    );
  });
};

const getDiscoverMoreCompanies =
  (params = {}) =>
  async (dispatch) => {
    dispatch({ type: types.FETCH_DISCOVER_MORE_COMPANIES });

    let industryInput = params.industryIds
      ? `industryIds: ${JSON.stringify(params.industryIds)},`
      : "";

    let startCursorInput = params.before
      ? `before: "${params.after}",`
      : `before: "",`;

    let endCursorInput = params.after
      ? `after: "${params.after}",`
      : `after: "",`;

    let firstNumInput = params.first
      ? `first: "${params.first}",`
      : `first: ${null},`;

    let lastNumInput = params.last
      ? `last: "${params.last}",`
      : `last: ${null},`;

    let queryInput =
      firstNumInput +
      lastNumInput +
      startCursorInput +
      endCursorInput +
      industryInput;

    // Removing trailing ","
    if (queryInput.charAt(queryInput.length - 1) == ",") {
      queryInput = queryInput.substring(0, queryInput.length - 1);
    }

    const payload = {
      query: `{
        companyLists(${queryInput}) {
          totalCount
          pageInfo {
            startCursor
            hasNextPage
            hasPreviousPage
            endCursor
          }          
            nodes {
              company { 
                ${companyListsAttribute}
              }
            }
          }
        } 
    `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          // if (response.data?.data?.companyLists) {
          const { pageInfo } = response.data?.data?.companyLists;
          const companyList = response?.data?.data?.companyLists.nodes;

          return dispatch({
            type: types.FETCH_DISCOVER_MORE_COMPANIES_SUCCEED,
            payload: {
              companyEndCursor: pageInfo?.endCursor,
              hasNextPage: pageInfo?.hasNextPage ?? false,
              companyList: companyList,
            },
          });
        }
        // }
        return dispatch({
          type: types.FETCH_DISCOVER_MORE_COMPANIES_FAILED,
        });
      });
  };

const clearDiscoverMoreCompanyList = () => async (dispatch) => {
  return new Promise((resolve) => {
    return resolve(
      dispatch({
        type: types.CLEAR_DISCOVER_MORE_COMPANY_LIST,
      })
    );
  });
};

const updateStateRegions = (stateRegions) => async (dispatch) => {
  return new Promise((resolve) => {
    return resolve(
      dispatch({
        type: types.UPDATE_STATE_REGION,
        stateRegions: stateRegions,
      })
    );
  });
};

const getTopEightCompanies =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({ type: types.FETCHING_COMPANIES });

    let keywordInput =
      params.keyword || params.keyword == ""
        ? `keyword: "${params.keyword}",`
        : "";

    let industryInput = params.industryIds
      ? `industryIds: ${JSON.stringify(params.industryIds)},`
      : "";

    let locationInput = params.stateRegions
      ? `stateRegions: ${JSON.stringify(params.stateRegions)},`
      : "";

    let companySizeInput = params.companySizes
      ? `companySizeIds: ${JSON.stringify(params.companySizes)},`
      : "";

    let referralTokenInput = params.referralToken
      ? `referralToken: "${params.referralToken}",`
      : "";

    let startCursorInput = params.before
      ? `before: "${params.after}",`
      : `before: "",`;

    let endCursorInput = params.after
      ? `after: "${params.after}",`
      : `after: "",`;

    let firstNumInput = params.first
      ? `first: ${params.first},`
      : `first: ${null},`;

    let lastNumInput = params.last ? `last: ${params.last},` : `last: ${null},`;

    let queryInput =
      firstNumInput +
      lastNumInput +
      startCursorInput +
      endCursorInput +
      keywordInput +
      industryInput +
      locationInput +
      referralTokenInput +
      companySizeInput;

    // Removing trailing ","
    if (queryInput.charAt(queryInput.length - 1) == ",") {
      queryInput = queryInput.substring(0, queryInput.length - 1);
    }

    const payload = {
      query: `{
        companyLists(${queryInput}) {
          totalCount
          pageInfo {
            startCursor
            hasNextPage
            hasPreviousPage
            endCursor
          }
          edges {
            node {
              company { 
                ${companyListsAttribute}
              }
            }
          }
        }
      }    
    `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", {}, payload)
      .then(async (response) => {
        if (response.status === 200) {
          if (response.data?.data?.companyLists) {
            const { edges, pageInfo } = response.data?.data?.companyLists;

            let companyList = edges?.map((edge) => {
              return edge?.node?.company;
            });

            companyList = companyList?.filter((company) => {
              return company !== null;
            });

            return dispatch({
              type: types.FETCH_COMPANIES_SUCCEED,
              payload: {
                keyword: params.keyword,
                refresh: params.refresh,
                companyEndCursor: pageInfo?.endCursor,
                hasNextPage: pageInfo?.hasNextPage ?? false,
                companyList: companyList,
                currentLoadingCompanyKey: params.currentLoadingCompanyKey,
              },
            });
          }
        }
        return dispatch({
          type: types.FETCH_COMPANIES_FAILED,
          currentLoadingCompanyKey: params.currentLoadingCompanyKey,
        });
      });
  };

/*
 * Params:
 * 1) canCancel
 * 2) cancelToken
 */
const getCompaniesForSitemap = (params = {}) => {
  const payload = {
    query: `{
      allCompaniesNotPaginated(limit:5000){
        slug
      }
    }`,
  };

  return api
    .apiCall("/api/job_seeker/v1/graphql", params, payload, true)
    .then(async (response) => {
      if (response.status === 200) {
        const companies = response.data.data?.allCompaniesNotPaginated;
        if (companies != null) {
          return companies;
        }
        if (companies === null) {
          return null;
        }
        return null;
      }
    });
};

/*
 * Params:
 * 1) page
 * 2) industryId
 * 3) companySize
 * 4) location - Need to wait for backend
 * 3) canCancel
 * 4) cancelToken
 */
const getCompanySuggestions =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({ type: types.FETCH_COMPANY_SUGGESTION });

    let keywordInput =
      params.keyword || params.keyword == ""
        ? `keyword: "${params.keyword}",`
        : "";

    let industryInput = params.industryIds
      ? `industryIds: ${JSON.stringify(params.industryIds)},`
      : "";

    let locationInput = params.stateRegions
      ? `stateRegions: ${JSON.stringify(params.stateRegions)},`
      : "";

    let companySizeInput = params.companySizes
      ? `companySizeIds: ${JSON.stringify(params.companySizes)},`
      : "";

    //removed page because companyLists does not accept arguement 'page' and not showing company suggestion
    let queryInput =
      keywordInput +
      industryInput +
      locationInput +
      companySizeInput;

    //Removing trailing ","
    if (queryInput.charAt(queryInput.length - 1) == ",") {
      queryInput = queryInput.substring(0, queryInput.length - 1);
    }

    const payload = {
      query: `{
        companyLists(${queryInput}) {
          nodes {
            company {
              id
              name
              slug
            }
          }
        }
      }
      `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          if (response.data.data.companyLists.nodes) {
            let companySuggestions = response.data.data.companyLists.nodes;

            return dispatch({
              type: types.FETCH_COMPANY_SUGGESTION_SUCCEED,
              payload: {
                refresh: params.refresh,
                companySuggestions: companySuggestions,
                suggestionPage: params.page,
                currentSuggestionKey: params.currentSuggestionKey,
              },
            });
          }
        }

        return dispatch({
          type: types.FETCH_COMPANY_SUGGESTION_FAILED,
          currentSuggestionKey: params.currentLoadingJobsKey,
        });
      })
      .catch((error) => {
        return dispatch({
          type: types.FETCH_COMPANY_SUGGESTION_FAILED,
          currentSuggestionKey: params.currentLoadingJobsKey,
        });
      });
  };

/*
 * Params:
 * 1) companyId
 * 2) canCancel
 * 3) cancelToken
 */
const getCompany =
  (params = {}) =>
  async (dispatch) => {
    dispatch({ type: types.FETCHING_COMPANY });

    const payload = {
      query: `{
      company(id: "${params.companyId}") {
        ${companyAttributes}
        ${profileAttributes}
        benefits {
          title
          description
          iconCode
        }
      }
    }    
  `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const { company } = response.data?.data;
          if (company != null) {
            return dispatch({
              type: types.FETCHING_COMPANY_SUCCEED,
              payload: { selectedCompany: company },
            });
          }
          if (company === null) {
            return dispatch({
              type: types.FETCHING_COMPANY_FAILED,
              companyNotFound: true,
            });
          }
        }
        return dispatch({ type: types.FETCHING_COMPANY_FAILED });
      });
  };

/*
 * Params:
 * 1) companyId
 */

/*
 * Params:
 * 1) endCursor
 * 2) reload
 * 3) canCancel
 * 4) cancelToken
 */
const fetchCompanyBookmarkList =
  (params = {}) =>
  (dispatch, getState) => {
    dispatch({ type: types.FETCH_COMPANY_BOOKMARK_LIST });
    const apiUrl = setCountryApi(params);

    const payload = {
      query: `{
      bookmarkCompanies(after: "${params.endCursor}") {
        pageInfo {
          startCursor
          endCursor
          hasPreviousPage
          hasNextPage
        }
        nodes {
          id
          createdAt
          company {
            ${companyAttributes}
          }
        }
      }
    }
  `,
    };

    return api.apiCall(apiUrl, params, payload).then(async (response) => {
      if (response.status === 200) {
        if (
          response.data &&
          response.data.data &&
          response.data.data.bookmarkCompanies
        ) {
          const { nodes, pageInfo } = response.data.data.bookmarkCompanies;
          if (nodes != null) {
            return dispatch({
              type: types.FETCH_COMPANY_BOOKMARK_LIST_SUCCEED,
              payload: { nodes, pageInfo },
            });
          }
        }
      }
      return dispatch({ type: types.FETCH_COMPANY_BOOKMARK_LIST_FAILED });
    });
  };

/*
 * Params:
 * 1) canCancel
 * 2) cancelToken
 */
const getIndustries =
  (params = {}) =>
  async (dispatch, getState) => {
    const payload = {
      query: `{
        industries {
          id
          title
          slug
        }
      }      
    `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const industries = response.data.data.industries;
          return dispatch({
            type: types.FETCHING_INDUSTRY_SUCCEED,
            industries: industries,
          });
        } else {
          return dispatch({
            type: types.FETCHING_INDUSTRY_FAILED,
          });
        }
      });
  };

const getIndustriesWithoutRedux = (params = {}) => {
  const payload = {
    query: `{
        industries {
          id
          title
          slug
        }
      }      
    `,
  };

  return api
    .apiCall("/api/job_seeker/v1/graphql", params, payload)
    .then(async (response) => {
      if (response.status === 200) {
        const industries = response.data.data.industries;
        return industries;
      }
    });
};

const getCompanySuggestion =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({ type: types.FETCHING_COMPANY_SUGGESTION });
    const payload = {
      query: `{
        featuredCompanies{
          nodes{
          ${companyAttributes}
          }
        }
      }      
    `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const discoverCompanies =
            response.data?.data?.featuredCompanies?.nodes;
          return dispatch({
            type: types.FETCHING_COMPANY_SUGGESTION_SUCCEED,
            payload: discoverCompanies ? discoverCompanies : [],
          });
        } else {
          return dispatch({
            type: types.FETCHING_COMPANY_SUGGESTION_FAILED,
          });
        }
      });
  };

const getStateRegions =
  (params = {}) =>
  async (dispatch, getState) => {
    const payload = {
      query: `{
      stateRegions {
          id
          state
          slug
        }
      }      
    `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const stateRegions = response.data.data.stateRegions;
          return dispatch({
            type: types.FETCHING_STATE_REGION_SUCCEED,
            payload: stateRegions,
          });
        } else {
          return dispatch({
            type: types.FETCHING_STATE_REGION_FAILED,
          });
        }
      });
  };

const getStateRegionsWithoutRedux = (params = {}) => {
  const payload = {
    query: `{
        stateRegions {
          id
          state
          slug
        }
      }      
    `,
  };

  return api
    .apiCall("/api/job_seeker/v1/graphql", params, payload)
    .then(async (response) => {
      if (response.status === 200) {
        const stateRegions = response.data.data.stateRegions;
        return stateRegions;
      }
    });
};

const getCompanySizes =
  (params = {}) =>
  async (dispatch, getState) => {
    const payload = {
      query: `{
        companySizes {
          id
          title
          slug
        }
      }      
    `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const companySizes = response.data.data.companySizes;
          return dispatch({
            type: types.FETCHING_COMPANY_SIZE_SUCCEED,
            companySizes: companySizes,
          });
        } else {
          return dispatch({
            type: types.FETCHING_COMPANY_SIZE_FAILED,
          });
        }
      });
  };

const getCompanySizesWithoutRedux = (params = {}) => {
  const payload = {
    query: `{
        companySizes {
          id
          title
          slug
        }
      }      
    `,
  };

  return api
    .apiCall("/api/job_seeker/v1/graphql", params, payload)
    .then(async (response) => {
      if (response.status === 200) {
        const companySizes = response.data.data.companySizes;
        return companySizes;
      }
    });
};

// For SEO
/*
 * Params:
 * 1) companyId
 * 2) canCancel
 * 3) cancelToken
 */

const getCompanyWithoutRedux = (params = {}) => {
  const payload = {
    query: `{
    company(id: "${params.companyId}") {
      ${companyAttributes}
      ${profileAttributes}
      benefits {
        title
        description
        iconCode
      }
    }
  }    
`,
  };

  return api
    .apiCall("/api/job_seeker/v1/graphql", params, payload, true)
    .then(async (response) => {
      if (response.status === 200) {
        const company = response.data.data.company;
        if (company != null) {
          return company;
        }
        if (company === null) {
          return { companyNotFound: true };
        }
      }
      return { companyNotFound: true };
    });
};

// For SEO
/*
 * Params:
 * 1) canCancel
 * 2) cancelToken
 */
const getCompaniesForStaticPage =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({ type: types.FETCHING_COMPANIES });

    const payload = {
      query: `{
        allCompaniesNotPaginated(limit:30){
          id
          name
          slug
        }
      }`,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          if (response.data.data?.allCompaniesNotPaginated) {
            const companies = response.data.data?.allCompaniesNotPaginated;

            return dispatch({
              type: types.FETCH_STATIC_COMPANIES_LIST_SUCCEED,
              payload: {
                staticCompanies: companies,
              },
            });
          }
        }
        return dispatch({
          type: types.FETCH_STATIC_COMPANIES_LIST_FAILED,
        });
      });
  };

// For SEO
const getCompanyBookmarkStatus =
  (params = {}) =>
  async (dispatch) => {
    dispatch({ type: types.FETCHING_COMPANY_BOOKMARK });

    const payload = {
      query: `{
          company(id: "${params.companyId}") {
            ${companyAttributes}
          }
        }    
      `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const { company } = response.data?.data;
          if (company != null) {
            return dispatch({
              type: types.FETCHING_COMPANY_BOOKMARK_SUCCEED,
              payload: { selectedCompany: company },
            });
          }
          if (company === null) {
            return dispatch({
              type: types.FETCHING_COMPANY_BOOKMARK_FAILED,
              companyNotFound: true,
            });
          }
        }
        return dispatch({ type: types.FETCHING_COMPANY_BOOKMARK_FAILED });
      });
  };

const getCompanyAffiliates =
  (params = {}) =>
  async (dispatch) => {
    dispatch({ type: types.FETCH_AFFILIATE_COMPANY });

    const payload = {
      query: `{
          company(id: "${params.companyId}") {
            id
            affiliatedCompany {
              company {
                id
                name
                logo
                industry
                jobCount
                slug      
                coverImageThumb
                bookmark
              }
            }  
            companyAffiliation {
              affiliatedCompany {
                id
                name
                logo
                industry
                jobCount
                slug      
                coverImageThumb
                bookmark
              }
            }
          }
        }
      `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then((response) => {
        if (response.status === 200) {
          const { company } = response.data?.data;
          if (company != null) {
            return dispatch({
              type: types.FETCH_AFFILIATE_COMPANY_SUCCEED,
              payload: { company },
            });
          }
          if (company === null) {
            return dispatch({
              type: types.FETCH_AFFILIATE_COMPANY_FAILED,
              companyNotFound: true,
            });
          }
        }
        return dispatch({ type: types.FETCH_AFFILIATE_COMPANY_FAILED });
      });
  };

/*
 * Params:
 * 1) companyId
 * 2) canCancel
 * 3) cancelToken
 */

const getCompanyJobsWithoutRedux = (params = {}) => {
  const payload = {
    query: `{
    companyJobLists(companyId: "${params.companyId}") {
      nodes {
      ${jobAttributes}
      }
    }
  }    
`,
  };

  return api
    .apiCall("/api/job_seeker/v1/graphql", params, payload)
    .then(async (response) => {
      if (response.status === 200) {
        const companyJobLists = response.data.data.companyJobLists.nodes;
        if (companyJobLists != null) {
          return companyJobLists;
        }
        if (companyJobLists === null) {
          return null;
        }
      }
      return null;
    });
};

const getCompanyJobs =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({ type: types.FETCH_COMPANY_JOBS });

    const payload = {
      query: `{
        companyJobLists(companyId: "${params.companyId}") {
          nodes {
            id
            title
            jobType
            tracks {
              id
              title
            }
            stateRegion
            location
            bookmark
            slug
            maxYearsExperience
            minYearsExperience
            boosted
            spotlight
            keywordHighlight
            scraped
            jobSlotType
            salary
            careerLevel
            
            company {
              id
              name
              logo
            }
          }
        }
      }`,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const companyJobs = response.data?.data?.companyJobLists?.nodes;
          return dispatch({
            type: types.FETCH_COMPANY_JOBS_SUCCEED,
            payload: companyJobs,
          });
        } else {
          return dispatch({
            type: types.FETCH_COMPANY_JOBS_FAILED,
          });
        }
      });
  };

const getDiscoverCompanies =
  (params = {}) =>
  async (dispatch) => {
    let industryInput = params.industryId
      ? `industryIds: [${params.industryId}],`
      : "";
    let companySizeInput = params.companySize
      ? `companySizeIds: [${params.companySize}]`
      : "";

    let queryInput = `after: "",` + industryInput + companySizeInput;
    //Removing trailing ","
    if (queryInput.charAt(queryInput.length - 1) == ",") {
      queryInput = queryInput.substring(0, queryInput.length - 1);
    }
    const payload = {
      query: `{
          companyLists(${queryInput}) {
            nodes {
              company {
                id
                name
                typeId
                sizeId
                industry
                industryId
                logo
                coverImage
                coverImageThumb
                jobCount
                bookmark
                slug
                logo
                hide
              }
            }
          }
        }    
      `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const discoverCompanies = response.data?.data?.companyLists?.nodes;
          return dispatch({
            type: types.FETCH_DISCOVER_COMPANIES_SUCCEED,
            payload: discoverCompanies,
          });
        } else {
          return dispatch({
            type: types.FETCH_DISCOVER_COMPANIES_FAILED,
          });
        }
      });
  };

const updateSelectedCompany = (selectedCompany) => async (dispatch) => {
  return new Promise((resolve) => {
    return resolve(
      dispatch({
        type: types.UPDATE_SELECTED_COMPANY,
        selectedCompany: selectedCompany,
      })
    );
  });
};

/*
 * Params:
 * 1) companyId
 * 2) canCancel
 * 3) cancelToken
 */
const bookmarkCompany =
  (params = {}) =>
  async (dispatch) => {
    dispatch({ type: types.BOOKMARK_COMPANY });

    const payload = {
      query: `mutation {
      bookmarkCompany(input: {companyId: "${params.companyId}"}) {
        bookmarkCompany {
          company {
            id
            bookmark
          }
        }
      }
    }
  `,
    };
    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const bookmarkCompany = response?.data?.data?.bookmarkCompany;

          if (bookmarkCompany != null) {
            dispatch({
              type: types.UPDATE_SELECTED_COMPANY,
              selectedCompany: bookmarkCompany?.bookmarkCompany?.company,
            });

            return dispatch({
              type: types.BOOKMARK_COMPANY_SUCCEED,
              payload: { bookmarkCompany },
            });
          }
          if (bookmarkCompany === null) {
            return dispatch({
              type: types.BOOKMARK_COMPANY_FAILED,
              payload: { message: "ERROR" },
            });
          }
        }
        return dispatch({
          type: types.BOOKMARK_COMPANY_FAILED,
          payload: { message: "ERROR" },
        });
      });
  };

/*
 * Params:
 * 1) companyId
 * 2) canCancel
 * 3) cancelToken
 */

const unBookmarkCompany =
  (params = {}) =>
  async (dispatch) => {
    dispatch({ type: types.UNBOOKMARK_COMPANY });
    const payload = {
      query: `mutation {
      unBookmarkCompany(input: {companyId: "${params.companyId}"}) {
        success
        company {
          id
          bookmark
        }
      }
    }
  `,
    };

    let response;

    if (params.useFullUrl) {
      const apiUrl = setCountryApi(params);
      response = await api.apiCall(apiUrl, params, payload);
    } else {
      response = await api.apiCall(
        "/api/job_seeker/v1/graphql",
        params,
        payload
      );
    }

    if (response.status === 200) {
      const success = response.data.data.unBookmarkCompany.success;
      const unBookmarkCompany = response?.data?.data?.unBookmarkCompany;

      if (success) {
        dispatch({
          type: types.UPDATE_SELECTED_COMPANY,
          selectedCompany: unBookmarkCompany?.company,
        });

        return dispatch({
          type: types.UNBOOKMARK_COMPANY_SUCCEED,
          selectedCompany: unBookmarkCompany?.company,
        });
      } else if (!success) {
        return dispatch({
          type: types.UNBOOKMARK_COMPANY_FAILED,
          payload: { message: "ERROR" },
        });
      }
    }
  };

const bookmarkDiscoverCompany =
  (params = {}) =>
  async (dispatch) => {
    dispatch({ type: types.BOOKMARK_DISCOVER_COMPANY });

    const payload = {
      query: `mutation {
      bookmarkCompany(input: {companyId: "${params.companyId}"}) {
        bookmarkCompany {
          id
          createdAt
          company {
            id
            bookmark
          }
        }
      }
    }
  `,
    };
    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const bookmarkCompany = response.data.data.bookmarkCompany;
          if (bookmarkCompany != null) {
            return dispatch({
              type: types.BOOKMARK_DISCOVER_COMPANY_SUCCEED,
              payload: bookmarkCompany.bookmarkCompany.company,
            });
          }
          if (bookmarkCompany === null) {
            return dispatch({
              type: types.BOOKMARK_COMPANY_FAILED,
              payload: { message: "ERROR" },
            });
          }
        }
        return dispatch({
          type: types.BOOKMARK_COMPANY_FAILED,
          payload: { message: "ERROR" },
        });
      });
  };

const unBookmarkDiscoverCompany =
  (params = {}) =>
  async (dispatch) => {
    dispatch({ type: types.UNBOOKMARK_DISCOVER_COMPANY });
    const payload = {
      query: `mutation {
      unBookmarkCompany(input: {companyId: "${params.companyId}"}) {
        success
        company {
          id
          bookmark
        }
      }
    }
  `,
    };
    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const bookmarkCompany = response.data.data.unBookmarkCompany;
          if (bookmarkCompany?.success) {
            return dispatch({
              type: types.UNBOOKMARK_DISCOVER_COMPANY_SUCCEED,
              payload: bookmarkCompany.company,
            });
          } else if (!bookmarkCompany?.success) {
            return dispatch({
              type: types.UNBOOKMARK_COMPANY_FAILED,
              payload: { message: "ERROR" },
            });
          }
        }
        return dispatch({
          type: types.UNBOOKMARK_COMPANY_FAILED,
          payload: { message: "ERROR" },
        });
      });
  };

const bookmarkCompanyAffiliate =
  (params = {}) =>
  async (dispatch) => {
    dispatch({ type: types.BOOKMARK_AFFILIATE_COMPANY });

    const payload = {
      query: `mutation {
    bookmarkCompany(input: {companyId: "${params.companyId}"}) {
      bookmarkCompany {
        company {
          id
          bookmark
        }
      }
    }
  }
`,
    };
    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const bookmarkCompany = response?.data?.data?.bookmarkCompany;

          if (bookmarkCompany != null) {
            return dispatch({
              type: types.BOOKMARK_AFFILIATE_COMPANY_SUCCEED,
              payload: bookmarkCompany?.bookmarkCompany?.company,
            });
          }
          if (bookmarkCompany === null) {
            return dispatch({
              type: types.BOOKMARK_AFFILIATE_COMPANY_FAILED,
              payload: { message: "ERROR" },
            });
          }
        }
        return dispatch({
          type: types.BOOKMARK_AFFILIATE_COMPANY_FAILED,
          payload: { message: "ERROR" },
        });
      });
  };

const unBookmarkCompanyAffiliate =
  (params = {}) =>
  async (dispatch) => {
    dispatch({ type: types.UNBOOKMARK_AFFILIATE_COMPANY });
    const payload = {
      query: `mutation {
    unBookmarkCompany(input: {companyId: "${params.companyId}"}) {
      success
      company {
        id
        bookmark
      }
    }
  }
`,
    };
    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const success = response.data.data.unBookmarkCompany.success;
          const unBookmarkCompany = response?.data?.data?.unBookmarkCompany;

          if (success) {
            return dispatch({
              type: types.UNBOOKMARK_AFFILIATE_COMPANY_SUCCEED,
              payload: unBookmarkCompany?.company,
            });
          } else if (!success) {
            return dispatch({
              type: types.UNBOOKMARK_AFFILIATE_COMPANY_FAILED,
              payload: { message: "ERROR" },
            });
          }
        }
        return dispatch({
          type: types.UNBOOKMARK_AFFILIATE_COMPANY_FAILED,
          payload: { message: "ERROR" },
        });
      });
  };

const bookmarkJobCompany =
  (params = {}) =>
  async (dispatch) => {
    dispatch({ type: types.BOOKMARK_COMPANY_JOB });

    const payload = {
      query: `mutation {
          bookmarkJob(input: {jobId: "${params.jobId}"}) {
            bookmarkJob {
              id
              job {
                id
                bookmark
              }
            }
          }
        }`,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const bookmarkJob = response.data.data.bookmarkJob;
          if (bookmarkJob != null) {
            return dispatch({
              type: types.BOOKMARK_COMPANY_JOB_SUCCEED,
              payload: bookmarkJob.bookmarkJob.job,
            });
          }
          if (bookmarkCompany === null) {
            return dispatch({
              type: types.BOOKMARK_COMPANY_JOB_FAILED,
              payload: { message: "ERROR" },
            });
          }
        }
        return dispatch({
          type: types.BOOKMARK_COMPANY_JOB_FAILED,
          payload: { message: "ERROR" },
        });
      });
  };

const unBookmarkJobCompany =
  (params = {}) =>
  async (dispatch) => {
    dispatch({ type: types.UNBOOKMARK_COMPANY_JOB });
    const payload = {
      query: `mutation {
          unBookmarkJob(input: {jobId: "${params.jobId}"}) {
          job {
            id
            bookmark
          }
          success
        }
      }`,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const bookmarkJob = response.data.data.unBookmarkJob;
          if (bookmarkJob?.success) {
            return dispatch({
              type: types.UNBOOKMARK_COMPANY_JOB_SUCCEED,
              payload: bookmarkJob.job,
            });
          } else if (!bookmarkJob?.success) {
            return dispatch({
              type: types.UNBOOKMARK_COMPANY_JOB_FAILED,
              payload: { message: "ERROR" },
            });
          }
        }
        return dispatch({
          type: types.UNBOOKMARK_COMPANY_JOB_FAILED,
          payload: { message: "ERROR" },
        });
      });
  };

const getCompanyPageBanner =
  (params = {}) =>
  async (dispatch) => {
    const payload = {
      query: `
      {
        homepageCompanyBanners {
          nodes {
            title
            chipText
            description
            image
            companyId
            companyName
            company {
              logo
              slug
            }
          }
        }
      }      
      `,
    };

    const response = await api.apiCall(
      "/api/job_seeker/v1/graphql",
      params,
      payload
    );

    if (response.status !== 200) {
      return dispatch({
        type: types.FETCH_COMPANY_BANNER_FAILED,
      });
    }

    const { nodes } = response.data?.data?.homepageCompanyBanners || {};

    // THERE WILL ONLY BE ONE BANNER UP AT A TIME, JUST RETURN THE FIRST ONE
    if (Array.isArray(nodes) && nodes.length > 0) {
      return dispatch({
        type: types.FETCH_COMPANY_BANNER_SUCCEED,
        banner: nodes[0],
      });
    } else {
      return dispatch({
        type: types.FETCH_COMPANY_BANNER_SUCCEED,
        banner: {},
      });
    }
  };

const getCompaniesMicrosite =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({ type: types.FETCHING_COMPANIES });

    const industryListInput = params?.industryIdList
      ? `industryIdList:[${params?.industryIdList}]`
      : `industryIdList:null`;

    const payload = {
      query: `{
        micrositeForCompanies(companyName: "${params?.companyName}", first:${params?.first}, last:${params?.last}, after:"${params?.after}", before:"${params?.before}", ${industryListInput}, keyword:"${params?.keyword}") {
          nodes {
            id
            name
            coverImage
            name
            industry
            slug
            isMagic
            isMdec
          }
          pageInfo {
            endCursor
            hasNextPage
          }
        }
      }    
    `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          if (response?.data?.data?.micrositeForCompanies) {
            const companyList =
              response?.data?.data?.micrositeForCompanies.nodes;
            const pageInfo =
              response.data?.data?.micrositeForCompanies?.pageInfo;

            return dispatch({
              type: types.FETCH_MICROSITE_COMPANIES_SUCCEED,
              payload: {
                companyList: companyList,
                pageInfo: pageInfo,
              },
            });
          }
        }
        return dispatch({
          type: types.FETCH_MICROSITE_COMPANIES_FAILED,
        });
      });
  };

const fetchEmployerTrustedBrand = () => async (dispatch) => {
  const isMalaysia = process.env.NEXT_PUBLIC_JSW_GEOLOCATION === "my";
  const queryKey = isMalaysia ? "forEmployerPage" : "forEmployerPageSg";

  const payload = {
    query: `
      {
        ${queryKey} {
          data {
            attributes {
              trustedBrandLogos {
                brandLogo {
                  data {
                    attributes {
                      url
                      alternativeText
                    }
                  }
                }
                clickThroughURL
              }
            }
          }
        }
      }      
      `,
  };

  const response = await strapiCall(payload);

  if (response.status === 200) {
    const { trustedBrandLogos: trustedBrandLogosMy } =
      response.data?.data?.forEmployerPage?.data?.attributes || {};

    const { trustedBrandLogos: trustedBrandLogosSg } =
      response.data?.data?.forEmployerPageSg?.data?.attributes || {};

    return dispatch({
      type: types.FETCH_EMPLOYER_TRUSTED_BRAND,
      logos: isMalaysia ? trustedBrandLogosMy : trustedBrandLogosSg || [],
    });
  } else {
    return {
      type: types.FETCH_EMPLOYER_TRUSTED_BRAND_FAILED,
    };
  }
};

const fetchEmployerTestimonials = () => async (dispatch) => {
  const payload = {
    query: `
    {
      forEmployerPage {
        data {
          attributes {
            employerTestimonials {
              testimonial
              companyLogo {
                data {
                  attributes {
                    url
                  }
                }
              }
              employerName
              jobTitle
              companyName
              rating
            }
          }
        }
      }
    }    
    `,
  };

  const response = await strapiCall(payload);

  if (response.status === 200) {
    const { employerTestimonials } =
      response.data?.data?.forEmployerPage?.data?.attributes || {};

    return dispatch({
      type: types.FETCH_EMPLOYER_TESTIMONIALS,
      testimonials: employerTestimonials ?? [],
    });
  } else {
    return {
      type: types.FETCH_EMPLOYER_TESTIMONIALS_FAILED,
    };
  }
};

const fetchEmployerExternalJobBoard = () => (dispatch) => {
  const payload = {
    query: `
      {
        forEmployerPage {
          data {
            attributes {
              externalJobBoards {
                id
                jobBoardLogo {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }         
        `,
  };

  return strapiCall(payload).then(async (response) => {
    if (response.status === 200) {
      const { externalJobBoards } =
        response?.data?.data?.forEmployerPage?.data?.attributes || {};

      return dispatch({
        type: types.FETCH_EMPLOYER_EXTERNAL_JOB_BOARD_SUCCEED,
        payload: externalJobBoards ?? [],
      });
    }
  });
};

const fetchCompanyStatisticNumbers = () => async (dispatch) => {
  const payload = {
    query: `
    {
      forEmployerPage{
         data{
          attributes{
            companyStatistics{
              statisticNumber
              Category
            }
          }
        }
      }
    }`,
  };

  const response = await strapiCall(payload);

  if (response.status !== 200) {
    return [];
  }

  const { companyStatistics } =
    response?.data?.data?.forEmployerPage?.data?.attributes || {};

  if (!Array.isArray(companyStatistics)) {
    return [];
  } else {
    // data type will be array of object
    /**
     * {
     *    statisticsNumber: integer
     *    Category: string
     * }
     */
    return companyStatistics;
  }
};

const fetchEmployerHeroBanners = () => async (dispatch) => {
  const isMalaysia = process.env.NEXT_PUBLIC_JSW_GEOLOCATION === "my";
  const queryKey = isMalaysia ? "forEmployerPage" : "forEmployerPageSg";

  const payload = {
    query: `
    {
      ${queryKey} {
        data {
          attributes {
            heroTitle
            heroSubtitle
            heroBanners {
              desktopBanner {
                data {
                  attributes {
                    url
                  }
                }
              }
              tabletBanner {
                data {
                  attributes {
                    url
                  }
                }
              }
              mobileBanner {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }    
    `,
  };

  try {
    const response = await strapiCall(payload);

    if (response.status !== 200) return {};

    const { attributes } = response?.data?.data?.[queryKey]?.data || {};

    return attributes;
  } catch (error) {
    console.error("Error fetching employer hero banners data:", error);
    return {};
  }
};

const fetchEmployerTagline = () => async (dispatch) => {
  const isMalaysia = process.env.NEXT_PUBLIC_JSW_GEOLOCATION === "my";
  const queryKey = isMalaysia ? "forEmployerPage" : "forEmployerPageSg";

  const payload = {
    query: `
    {
      ${queryKey} {
        data {
          attributes {
            tagline {
              overline
              mainTitle
              subTitle
            }
          }
        }
      }
    }    
    `,
  };

  try {
    const response = await strapiCall(payload);

    if (response.status !== 200) return {};

    const { attributes } = response?.data?.data?.[queryKey]?.data || {};

    return attributes;
  } catch (error) {
    console.error("Error fetching employer tagline data:", error);
    return {};
  }
};

const fetchHireBetter = () => async (dispatch) => {
  const isMalaysia = process.env.NEXT_PUBLIC_JSW_GEOLOCATION === "my";
  const queryKey = isMalaysia ? "forEmployerPage" : "forEmployerPageSg";

  const payload = {
    query: `
    {
      ${queryKey} {
        data {
          attributes {
            hbhf {
              sectionTitle
              eventName
              imageUpload {
                data {
                  attributes {
                    url
                  }
                }
              }
              eventHeadline
              eventDescription
              eventDetails
              ctaButtonCopy
              ctaButtonLink
            }
          }
        }
      }
    }    
    `,
  };

  try {
    const response = await strapiCall(payload);

    if (response.status !== 200) return {};

    const { attributes } = response?.data?.data?.[queryKey]?.data || {};

    return attributes;
  } catch (error) {
    console.error("Error fetching employer tagline data:", error);
    return {};
  }
};

const fetchEmployerSolutions = () => async (dispatch) => {
  const isMalaysia = process.env.NEXT_PUBLIC_JSW_GEOLOCATION === "my";
  const queryKey = isMalaysia ? "forEmployerPage" : "forEmployerPageSg";

  const payload = {
    query: `
      {
        ${queryKey} {
          data {
            attributes {
              solutions {
                overview
                title
                subtitle
              }
              solutionsCard {
                title
                description
                image {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }    
    `,
  };

  try {
    const response = await strapiCall(payload);

    if (response.status !== 200) return {};

    const { attributes } = response?.data?.data?.[queryKey]?.data || {};

    return attributes;
  } catch (error) {
    console.error("Error fetching employer solutions:", error);
    return {};
  }
};

const fetchEmployerBeyondJob = () => async (dispatch) => {
  const isMalaysia = process.env.NEXT_PUBLIC_JSW_GEOLOCATION === "my";
  const queryKey = isMalaysia ? "forEmployerPage" : "forEmployerPageSg";

  const payload = {
    query: `
    {
      ${queryKey} {
        data {
          attributes {
            beyondJob {
              overview
              title
              subtitle
              coverImage {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
            beyondJobCard {
              title
              description
              image {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
    `,
  };

  try {
    const response = await strapiCall(payload);

    if (response.status !== 200) return {};

    const { attributes } = response?.data?.data?.[queryKey]?.data || {};

    return attributes;
  } catch (error) {
    console.error("Error fetching employer beyond job data:", error);
    return {};
  }
};

const fetchEmployerVideo = () => async (dispatch) => {
  const isMalaysia = process.env.NEXT_PUBLIC_JSW_GEOLOCATION === "my";
  const queryKey = isMalaysia ? "forEmployerPage" : "forEmployerPageSg";

  const payload = {
    query: `
    {
      ${queryKey} {
        data {
          attributes {
            companyVideo {
              overview
              title
              subtitle
              video {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
    `,
  };

  try {
    const response = await strapiCall(payload);

    if (response.status !== 200) return {};

    const { attributes } = response?.data?.data?.[queryKey]?.data || {};

    return attributes;
  } catch (error) {
    console.error("Error fetching employer video data:", error);
    return {};
  }
};

const fetchEmployerPlatformCard = () => async (dispatch) => {
  const isMalaysia = process.env.NEXT_PUBLIC_JSW_GEOLOCATION === "my";
  const queryKey = isMalaysia ? "forEmployerPage" : "forEmployerPageSg";

  const payload = {
    query: `
    {
      ${queryKey} {
        data {
          attributes {
            platformCard {
              id
              title
              description
              linkSrc
              coverImage {
                data {
                  attributes {
                  url
                  }
                }
              }
            }
          }
        }
      }
    }
    `,
  };

  try {
    const response = await strapiCall(payload);

    if (response.status !== 200) return {};

    const { attributes } = response?.data?.data?.[queryKey]?.data || {};

    return attributes;
  } catch (error) {
    console.error("Error fetching employer video data:", error);
    return {};
  }
};

const fetchEmployerContactCard = () => async (dispatch) => {
  const isMalaysia = process.env.NEXT_PUBLIC_JSW_GEOLOCATION === "my";
  const queryKey = isMalaysia ? "forEmployerPage" : "forEmployerPageSg";

  const payload = {
    query: `
    {
      ${queryKey} {
        data {
          attributes {
             contactCard {
              id
              title
              description
            }
          }
        }
      }
    }
    `,
  };

  try {
    const response = await strapiCall(payload);

    if (response.status !== 200) return {};

    const { attributes } = response?.data?.data?.[queryKey]?.data || {};

    return attributes;
  } catch (error) {
    console.error("Error fetching employer video data:", error);
    return {};
  }
};

const updateCompanySearchKeyword = (params) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_COMPANY_SEARCH_KEYWORD,
    keyword: params.keyword,
  });
};

export {
  bookmarkCompany,
  bookmarkCompanyAffiliate,
  bookmarkDiscoverCompany,
  bookmarkJobCompany,
  clearBookmarkCompanyList,
  clearCompanyList,
  clearCompanySuggestion,
  clearDiscoverMoreCompanyList,
  fetchCompanyBookmarkList,
  fetchCompanyStatisticNumbers,
  fetchEmployerBeyondJob,
  fetchEmployerContactCard,
  fetchEmployerExternalJobBoard,
  fetchEmployerHeroBanners,
  fetchEmployerPlatformCard,
  fetchEmployerSolutions,
  fetchEmployerTagline,
  fetchEmployerTestimonials,
  fetchEmployerTrustedBrand,
  fetchEmployerVideo,
  fetchHireBetter,
  getCompaniesForSitemap,
  getCompaniesForStaticPage,
  getCompaniesMicrosite,
  getCompany,
  getCompanyAffiliates,
  getCompanyBookmarkStatus,
  getCompanyJobs,
  getCompanyJobsWithoutRedux,
  getCompanyPageBanner,
  getCompanySizes,
  getCompanySizesWithoutRedux,
  getCompanySuggestion,
  getCompanySuggestions,
  getCompanyWithoutRedux,
  getDiscoverCompanies,
  getDiscoverMoreCompanies,
  getIndustries,
  getIndustriesWithoutRedux,
  getStateRegions,
  getStateRegionsWithoutRedux,
  getTopEightCompanies,
  unBookmarkCompany,
  unBookmarkCompanyAffiliate,
  unBookmarkDiscoverCompany,
  unBookmarkJobCompany,
  updateCompanyFilter,
  updateCompanySearchKeyword,
  updateSelectedCompany,
  updateStateRegions
};
